import React from "react";
import dynamic from 'next/dynamic';
import Script from 'next/script';
// Html Parser
import parse from "html-react-parser";
import {withTranslation} from "next-i18next";
import {CA, DEFAULT_LOCALES_LIST, UAE, UK} from "../../helper/constantHelper";
import reviewCss from "../../styles/review.module.scss";

// components
const HeaderSection = dynamic(() => import('../header/headerSection'));
const HireWriter = dynamic(() => import("./hireWriter"));
const Assignment = dynamic(() => import("./assignment"));
const AdsBanner = dynamic(() => import("./adsBanner"));
const Feedback = dynamic(() => import("./feedback"));
const Feature = dynamic(() => import("./feature"));
const Service = dynamic(() => import("./service"));
const Content = dynamic(() => import("./content"));
const Faq = dynamic(() => import("./faq"));
const Contact = dynamic(() => import("./contact"));
const AcademicService = dynamic(() => import("./academicService"));
const RecentArticles = dynamic(() => import("./recentArticles"));

const Home = (props) => {
  const {
    content: serviceData,
    service_type_data,
    paper_types_data,
    display_pages_data,
    reviews,
    pageName,
    metaDescription,
    topWriters,
    recentArticles,
    writersNames,
    recentArticlesContentReadTime,
    ratingData,
    heroImage,
    currLocale,
    linkLocale
  } = props;

  const localeUrl = DEFAULT_LOCALES_LIST.includes(currLocale) ? '' : `/${currLocale}`;
  const language = currLocale => {
    switch(currLocale) {
      case UK:
        return 'en-GB'
      case CA:
        return 'en-CA'
      case UAE:
        return 'en-AE'
      default:
        return 'en-US'
    }
  }

  const webSchema = `{
    "@context": "https://schema.org",
    "@type": "Website",
    "name": "CheapestEssay",
    "url": "${process.env.hostBaseUrl}${localeUrl}",
    "inLanguage": "${language(currLocale)}"
  }`;

  const viewSchema = `[{
    "@context": "https://schema.org",
    "@type": "ViewAction",
    "target": {
      "@type":  "EntryPoint",
      "urlTemplate": "android-app://com.cheapestessay.service/https/www/cheapestessay.com"
    }
  },
  {
    "@context": "https://schema.org",
    "@type": "ViewAction",
    "target": {
      "@type":  "EntryPoint",
      "urlTemplate": "ios-app://1447217562/https/www/cheapestessay.com"
    }
  }]`;

  const orgSchema = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "CheapestEssay",
    "url": "${process.env.hostBaseUrl}${localeUrl}",
    "logo": "${process.env.hostBaseUrl}/logo.svg",
    "description": "${props.metaDescription ? (props.metaDescription).replace(/"/g, '&quot;') : ''}",
    "email": "support@cheapestessay.com",
    "sameAs":   ["https://www.facebook.com/CheapestEssay/","https://twitter.com/CheapestEssay","https://www.instagram.com/cheapestessay/","https://www.youtube.com/channel/UCP45PAPiPMtFsmqNpkPJ8dg","https://www.linkedin.com/company/cheapest-essay","https://www.tiktok.com/@cheapestessay","https://www.sitejabber.com/reviews/cheapestessay.com","https://www.trustpilot.com/review/cheapestessay.com"],
    "foundingDate": "2014",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Columbus Ohio",
      "postOfficeBoxNumber": "43229",
      "addressLocality": "Columbus",
      "addressRegion": "Ohio",
      "postalCode": "43229",
      "addressCountry": {
        "@type": "Country",
        "name": "USA"
      }
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "(+)1.909.441.1414",
      "contactType": "customer support"
    }
  }`;

  return (
    <>

      <Script id="org-schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: orgSchema }} strategy="beforeInteractive" />
      <Script id="web-schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: webSchema }} strategy="beforeInteractive" />
      <Script id="view-schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: viewSchema }} strategy="beforeInteractive" />

      <HeaderSection serviceData={serviceData}
        service_type_data={service_type_data}
        paper_types_data={paper_types_data}
        heroImage={heroImage}
        display_pages_data={display_pages_data}
        currLocale={currLocale}
        linkLocale={linkLocale} />
      <HireWriter
        title={serviceData && serviceData.page_contents.initial_pitch_content}
        desc={serviceData && serviceData.page_contents && serviceData.page_contents.main_header ? parse(serviceData.page_contents.main_header.toString()) : ''}
        topWriters={topWriters}
        currLocale={currLocale}
        linkLocale={linkLocale}
      />
          <Assignment currLocale={currLocale} />
          <AdsBanner src="/discount-banner.svg" serviceContent={serviceData} linkLocale={linkLocale} />
          <Feedback reviewData={reviews}
                    ratingData={ratingData}
                    schemaPageName={pageName}
                    metaDescription={metaDescription}
                    currLocale={currLocale}
                    linkLocale={linkLocale} />
          <Feature currLocale={currLocale}
                   linkLocale={linkLocale} />
          <AcademicService currLocale={currLocale}
                           linkLocale={linkLocale} />
          <Service serviceData={serviceData} />
          <AdsBanner adsbanner={true} serviceContent={serviceData} currLocale={currLocale} linkLocale={linkLocale} />
          <div className={`${reviewCss.combinedContentWrapper} ${(serviceData !== undefined && serviceData === null) ? 'empty' : ''}`}>
            <Content serviceData={serviceData} />
          </div>
          <Faq faqData={serviceData} />
          <RecentArticles recentArticles={recentArticles} articleWriterNames={writersNames} readingTime={recentArticlesContentReadTime} />
          <Contact currLocale={currLocale} />

    </>
  );
}

export default withTranslation()(Home);